import React, {useEffect, useState} from 'react';

function NotFoundPage() {
    function onBtnClick(){
        window.location.replace('/')
    }
    return(
        <div className={'row d-flex'}>
            <p className={'text-center'}>Ma'lumot topilmadi</p>
            <div className={'col-3 center-align'}>
                <button className={'btn h-100'} onClick={onBtnClick}>
                    Asosiy oynaga o'tish
                </button>
            </div>

        </div>
    )
}

export default NotFoundPage;
