import './App.css';
import {
    Routes,
    Route,
} from 'react-router-dom';


import MainPage from './components/MainPage'
import DetailPage from './components/DetailUniversity'
import LayoutPage from './components/LayoutPage'
import PaymentForCourse from './components/Payment'
import PaymePage from './components/PaymePage'
import NotFoundPage from "./components/NotFoundPage";
import AllCourses from "./components/AllCourses";
import AboutPage from "./components/AboutPage";

function App() {
  return (
      <Routes>

          <Route path="/" element={<LayoutPage/>}>
              <Route path="/" element={<MainPage />} exact />
              {/*<Route path="/selling" element={<SellingPage />}/>*/}
              <Route path="/detail/:id" element={<DetailPage />}/>
              <Route path="/payment/" element={<PaymentForCourse />}/>
              <Route path="/order-payme" element={<PaymePage />}/>
              <Route path="/all-courses" element={<AllCourses />}/>
              <Route path="/about" element={<AboutPage />}/>
              <Route path="*" element={<NotFoundPage />}/>
          </Route>

          {/*<Route path="/login" element={<LoginPage />}/>*/}

          {/*<Route path="*" element={<NotFoundPage />} />*/}

          {/*<DashboardLayoutRoute path="/" component={<HomePage />} />*/}
      </Routes>
  );
}

export default App;
