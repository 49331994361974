import React, {useEffect, useState} from 'react';
import {Route, Link, Routes, useParams, useSearchParams} from 'react-router-dom'
import api from "../services/api";
import { useNavigate } from 'react-router-dom';

function PaymentForCourse() {
    const navigate = useNavigate();
    let params = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    let [univerid, setUniverId] = useState(null);
    let [name, setName] = useState('');
    let [phone, setPhone] = useState('');
    let [type_user, setTypeUser] = useState(0);
    let [region, setRegion] = useState(0);
    let [btnDisable, setbtnDisable] = useState(true);
    let [loading, setLoading] = useState(false);
    let [errorRequest, setErrorRequest] = useState(false);
    let [isPayme, setIsPayme] = useState(false);

    useEffect(() =>  {
        console.log(searchParams);
        setUniverId(searchParams.get("university"));
    }, []);

    function checkBtnDisable() {
        console.log(name)
        console.log(phone)
        console.log(type_user)
        console.log(region)
        if (name.length>0 && phone.length>0 && type_user!==0 && region!==0) {
            setbtnDisable(false)
        }else {
            setbtnDisable(true)
        }
    }

    function changeName(e) {
        // console.log(e.target.value)
        setName(e.target.value)
        // checkBtnDisable()
    }
    function changePhone(e) {
        // console.log(e.target.value)
        setPhone(e.target.value)
        // checkBtnDisable()
    }
    function selectTypeUser(e) {
        // console.log(e.target.value)
        setTypeUser(e.target.value)
        // checkBtnDisable()
    }
    function selectRegion(e) {
        // console.log(name)
        // console.log(phone)
        setRegion(e.target.value)
        // checkBtnDisable()
    }
    function isNumberKey(e) {
        let charCode = (e.which) ? e.which : e.keyCode;
        if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)){
            e.preventDefault();
            return false
        }
        return true;
    }
    function onBtnClick(){
        window.location.replace('/')
    }
    const onPaymentClick = async () => {
        // console.log('ina')
        // console.log(name)
        // console.log(phone)
        // console.log(region)
        // console.log(type_user)
        if (name.length>0 && phone.length>0 && region && parseInt(region)>0 && parseInt(region)<15 && parseInt(type_user)>0 && parseInt(type_user)<4){
            setLoading(true);
            let data = {
                name: name,
                phone: phone,
                region: parseInt(region),
                type_user: parseInt(type_user),
                univer_id : univerid
            }
            console.log(data)

            api.post('/create-order/', data).then(res=>{
                console.log(res)
                if (res.status === 200){
                    console.log(res)
                    let url = res.data.url
                    // navigate('/', { state: { payme_url: url} });
                    // let url = `https://checkout.paycom.uz/base64(m=65df09373c319dec9d8c7284;ac.order_id=${res.data.order_id};a=18000000)`
                    // window.location.replace('/');
                    // setTimeout(() => {
                    //     // Open a new tab with a specific URL
                    //     window.open(url, '_blank',);
                    // }, 1000);
                    window.open(url, '_blank')
                    setIsPayme(true)
                } else {
                    setErrorRequest(true)
                }
            }).catch(err=>{
                setErrorRequest(true)
                console.log(err)
            })
        }



    }

    if (loading){
        if (isPayme){
            return (
                <div className={'row d-flex'}>
                    <div className={'col-3 center-align'}>
                        <button className={'btn h-100'} onClick={onBtnClick}>
                            Asosiy oynaga o'tish
                        </button>
                    </div>
                </div>
            )
        }else {
            return (
                <div className={'text-center'}>
                    <section>
                        <div className="container com-sp pad-bot-70 pg-inn">
                            <div className="spinner-red">
                                <span>Ma'lumot yuklanmoqda</span>
                            </div>
                        </div>
                    </section>
                </div>
            )
        }


    }else if (errorRequest){
        return(
            <div className={'text-center'}>
                <section>
                    <div className="container com-sp pad-bot-70 pg-inn">
                        <div className="spinner-red">
                            <span>Xatolik yuz berdi qaytadan urinib ko'ring</span>
                        </div>
                    </div>
                </section>
            </div>
        )
    }else if (univerid){
            return(
                <div>
                    <section>
                        <div className="container com-sp pad-bot-70 pg-inn">
                            <div className="row">
                                <div className="cor">
                                    <div className="col-md-6">
                                        <div className="cor-top-deta cor-side-com">
                                            <div className="cor-top-deta">
                                                <div className="ho-st-login cor-apply field-com">
                                                    <div className="col s12 text-center">
                                                        <form className="col s12">
                                                            <div className="cor-left-app-tit">
                                                                <h4>Talaba bo'lish</h4>

                                                            </div>
                                                            <div className="row">
                                                                <div className="input-field col s12">
                                                                    <input className="validate" value={name} onChange={changeName}/>
                                                                    <label>F.I.O</label>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="input-field col s4">
                                                                    <input type="text" disabled={true} value={'+998'}/>
                                                                </div>
                                                                <div className="input-field col s8">
                                                                    <input type="text" pattern="[0-9]*" inputMode="numeric" className="validate" onChange={changePhone} onKeyPress={isNumberKey} maxLength={9}/>
                                                                    <label>Telefon</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="input-field col s12">
                                                                    <select defaultValue={0} onChange={selectTypeUser}>
                                                                        <option value={0}>
                                                                            Tanlang
                                                                        </option>
                                                                        <option value={1}>Maktab bitiruvchisi</option>
                                                                        <option value={2}>Kollej bitiruvchisi</option>
                                                                        <option value={3}>O'quvchi</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="input-field col s12">
                                                                    <select defaultValue={0} onChange={selectRegion}>
                                                                        <option value={0}>Viloyatni tanlang</option>
                                                                        <option value={1}>Andijon viloyati</option>
                                                                        <option value={2}>Buxoro viloyati</option>
                                                                        <option value={3}>Fargʻona viloyati</option>
                                                                        <option value={4}>Jizzax viloyati</option>
                                                                        <option value={5}>Namangan viloyati</option>
                                                                        <option value={6}>Navoiy viloyati</option>
                                                                        <option value={7}>Qashqadaryo viloyati</option>
                                                                        <option value={8}>Qoraqalpogʻiston Respublikasi</option>
                                                                        <option value={9}>Samarqand viloyati</option>
                                                                        <option value={10}>Sirdaryo viloyati</option>
                                                                        <option value={11}>Surxondaryo viloyati</option>
                                                                        <option value={12}>Toshkent viloyati</option>
                                                                        <option value={13}>Toshkent shahri</option>
                                                                        <option value={14}>Xorazm viloyati</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                        </form>
                                                        <div className="row">
                                                            <div className="input-field col s12">
                                                                <button className={'btn h-100'} style={{width:'100%'}} onClick={onPaymentClick}>
                                                                    To'lovni amalga oshirish
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <ul >
                                            <li>
                                                {/*<div className="collapsible-header">test</div>*/}
                                                <div >
                                                    <ul>
                                                        <li>
                                                            Atigi 180.000 so'm evziga hoziroq o'z joyingizni band qiling va talaba bo'lish orzuyingizni biz bilan haqiqatga aylantiring!
                                                        </li>
                                                        <br />
                                                        <li>
                                                            To'lov qilgandan keyin menejerimiz siz bilan bog'lanadi va qolgan hujjat ishlari bo'yicha sizga yordam beradi.

                                                            Kelajak bugundan boshlanadi!!!
                                                        </li>
                                                        <br />
                                                        <li>
                                                            <br />
                                                            Kelajak bugundan boshlanadi!!!
                                                            <br />
                                                            <br />
                                                        </li>


                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )
        } else {
            return(
                <div>
                    <section className={'text-center'}>
                        <div className="container com-sp pad-bot-70 pg-inn">
                            <h1>
                                Ma'lumot topilmadi
                            </h1>
                        </div>
                    </section>

                </div>
            )
        }


}

export default PaymentForCourse;
