import axios from 'axios';
// import {BASE_URL} from "./urls";

const BASE_URL = 'http://consult.pythonanywhere.com'
// const BASE_URL = 'http://127.0.0.1:8000'
// jwt token
// const JWTToken = localStorage.getItem('jwt');

// axios instance
const api = axios.create({
    baseURL: `${BASE_URL}/api/v1`
});

// export function apiSetHeader (name, value) {
//     if (value) {
//         api.defaults.headers[name] = value;
//     }
// }

// token bo'lsa zapros headerga qushamiz
// if (JWTToken) {
//     apiSetHeader('Authorization', `Bearer ${JWTToken}`);
// }

api.interceptors.request.use(config => {
    // user zaprosida token bo'lmasa
    // if (!config.headers['Authorization']) {
    //     console.log(config)
    // }

    return config;
}, error => {
    return Promise.reject(error);
});

export default api;
