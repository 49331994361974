import React, {useState, useEffect, useRef} from 'react';
import api from "../services/api";

function MainPage() {
    let [data, setData] = useState([]);
    useEffect(() =>  {
        api.get('/universities/').then(res => {
            console.log(res)
            setData(res.data.data)
        }).catch(err=>{
            console.log(err)
        })
    }, []);

    function onClickSearchButton() {
        console.log(navigator)
        let ft = document.getElementById("sf-box")
        console.log(ft.value)
        ft.value = ''
    }

    return (
        <div>
            <section>
                    <div id="myCarousel" className="carousel slide" data-ride="carousel">
                        {/* Wrapper for slides */}
                        <div className="carousel-inner">
                            <div className="item slider1 active">
                                <img src="/static/images/slider/1.jpg" alt="" />
                                <div className="carousel-caption slider-con">
                                    <h2>
                                        Xush kelibsiz <span>OCEAN CONSULT UNIVERSITY</span>
                                    </h2>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            {/* Popular Courses */}
                <section className="pop-cour">
                    <div className="container com-sp pad-bot-20">
                        <div className="row">
                            <div className="con-title">
                                <h2>
                                    <span>UNIVERSITETLAR</span>
                                </h2>
                            </div>
                        </div>
                        <div className="row">
                        {data.map((item, index)=>{
                            return(
                                <div className="col-md-6" key={'univer'+index}>
                                <div className="row">

                                        <div className="home-top-cour">
                                            {/*POPULAR COURSES IMAGE*/}
                                            <div className="col-md-3">
                                                {" "}
                                                <img src={"http://consult.pythonanywhere.com"+item.photo_url} alt="" width={120} height={120}/>
                                            </div>
                                            {/*POPULAR COURSES: CONTENT*/}
                                            <div className="col-md-9 home-top-cour-desc">
                                                <a href="/detail">
                                                    <h3>{item.name}</h3>
                                                </a>
                                                <h4>{item.category_text}</h4>
                                                <p>
                                                    {item.detail_text.toString().length>100?item.detail_text.toString().substr(0, item.detail_text.toString().lastIndexOf(' ', 97)):item.detail_text}
                                                </p>{" "}
                                                {/*<span className="home-top-cour-rat">4.2</span>*/}
                                                <div className="hom-list-share">
                                                    <ul>
                                                        <li>
                                                            <a href={"/detail/"+item.id}>
                                                                <i className="fa fa-bar-chart" aria-hidden="true" />{" "}
                                                                Batafsil
                                                            </a>{" "}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                            )
                        })}
                        </div>


                    </div>
                </section>
        </div>
    )
}

export default MainPage;
