import React, {useEffect, useState} from 'react';

function AboutPage() {
    function onBtnClick(){
        window.location.replace('/')
    }
    return(
        <section>
            <div className="container com-sp pad-bot-70">
                <div className="row">
                    <div className="cor about-sp">
                        <div className="ed-about-tit">
                            <div className="con-title">
                                <h2>Biz <span> haqimizda</span></h2>
                                <p>test</p>
                            </div>
                        </div>
                        <div className="pg-contact">
                            <div className="col-md-3 col-sm-6 col-xs-12 new-con new-con1">
                                <h2>Ocean <span>Consulting</span></h2>
                                <p>test</p>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-12 new-con new-con1"><img src="img/contact/1.html"
                                                                                               alt=""/>
                                <h4>Manzil</h4>
                                <p>Xorazm viloyati Urganch shahar
                                    <br/>Mo'ljal : Darital</p>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-12 new-con new-con3"><img src="img/contact/2.html"
                                                                                               alt=""/>
                                <h4>TELEFON MA'LUMOTLAR:</h4>
                                <p><a href="tel://+99862123456" className="contact-icon">Telefon: +998621234567</a>
                                    <br/> <a href="tel://+998991234567" className="contact-icon">Mobil telefon: +998991234567</a>
                                        <br/> <a href="mailto:talaba@mail.uz" className="contact-icon">Email:
                                            talaba@mail.uz</a></p>
                            </div>
                            {/*<div className="col-md-3 col-sm-6 col-xs-12 new-con new-con4"><img src="img/contact/3.html"*/}
                                                                                               {/*alt=""/>*/}
                                {/*<h4>Websayt</h4>*/}
                                {/*<p><a href="#">Website: www.mycompany.com</a>*/}
                                    {/*<br/> <a href="#">Facebook: www.facebook/my</a>*/}
                                        {/*<br/> <a href="#">Blog: www.blog.mycompany.com</a></p>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutPage;
