import React, {useEffect, useRef} from 'react';
import { NavLink, Outlet } from "react-router-dom";
import api from "../services/api";

function LayoutPage() {
    const btnRef = useRef()
    function onClickSearchButton() {
        console.log(navigator)
        let ft = document.getElementById("sf-box")
        console.log(ft.value)
        ft.value = ''
        api.get('/universities/').then(res=>{
            console.log(res)
        })
    }
    function openMenu(){
        // console.log(btnRef)
        btnRef.current.classList.add("ed-mm-act")
        // document.getElementById('.mob-btn-close-open').classList.add("ed-mm-act")
    }
    function closeMenu(){
        // document.getElementById('.mob-btn-close-open').classList.remove("ed-mm-act")
        btnRef.current.classList.remove("ed-mm-act")
    }
    return(
        <div>
                {/* MOBILE MENU */}
                <section>
                    <div className="ed-mob-menu">
                        <div className="ed-mob-menu-con">
                            <div className="ed-mm-left">
                                <div className="wed-logo">
                                    <a href="/">
                                        <img src="/static/images/logo34.png" alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="ed-mm-right">
                                <div className="ed-mm-menu">
                                    <a type={'button'} onClick={openMenu} className="ed-micon">
                                        <i className="fa fa-bars" />
                                    </a>
                                    <div ref={btnRef} id={'mob-btn-close-open'} className="ed-mm-inn" >
                                        <a type={'button'} onClick={closeMenu} className="ed-mi-close">
                                            <i className="fa fa-times" />
                                        </a>

                                        <ul>
                                            <li>
                                                <a href="/about">Biz haqimizda</a>
                                            </li>
                                            <li>
                                                <a href="/all-courses">Mavjud qabullar ro'yhati</a>
                                            </li>
                                            {/*<li>*/}
                                                {/*<a href="/contact">Biz bilan bog'lanish</a>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*HEADER SECTION*/}
                <section>
                    {/* TOP BAR */}
                    <div className="ed-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="ed-com-t1-left">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    Xorazm viloyati Urganch shahar
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">Telefon: +99 899 123 45 67</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* LOGO AND MENU SECTION */}
                    <div className="top-logo" data-spy="affix" data-offset-top={250}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="wed-logo">
                                        <a href="/">
                                            <img src="/static/images/logo34.png" alt="" />
                                        </a>
                                    </div>
                                    <div className="main-menu">
                                        <ul>
                                            <li className="about-menu">
                                                <a href="/about" className="mm-arr">
                                                    Biz haqimizda
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/all-courses">Mavjud qabullar</a>
                                            </li>
                                            {/*<li>*/}
                                                {/*<a href="/contact">Biz bilan bog'lanish</a>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </div>
                                </div>
                                <div className="all-drop-down-menu"></div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="search-top">*/}
                    {/*    <div className="container">*/}
                    {/*        <div className="row">*/}
                    {/*            <div className="col-md-12">*/}
                    {/*                <div className="search-form">*/}
                    {/*                    <form>*/}
                    {/*                        <div className="sf-type">*/}
                    {/*                            <div className="sf-input">*/}
                    {/*                                <input*/}
                    {/*                                    type="text"*/}
                    {/*                                    id="sf-box"*/}
                    {/*                                    placeholder="Yo'nalish bo'yicha qidirish"*/}
                    {/*                                />*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="sf-submit">*/}
                    {/*                            <input type="button" value={'Qidirish'} onClick={onClickSearchButton}/>*/}
                    {/*                        </div>*/}
                    {/*                    </form>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </section>

                <Outlet />
                <section className="wed-hom-footer">
                    <div className="container">
                        <div className="row wed-foot-link-1">
                            <div className="col-md-4 foot-tc-mar-t-o">
                                <h4>Bog'lanish</h4>
                                <p>
                                    Adres: Xorazm viloyati, Urganch shahar, 123.
                                </p>
                                <p>
                                    Telefon: <a href="#!">+99-899-123-45-67</a>
                                </p>
                                <p>
                                    Email: <a href="#!">info@talaba.uz</a>
                                </p>
                            </div>
                            <div className="col-md-4 foot-tc-mar-t-o">
                                <h4>BIZNI IJTIMOIY TARMOQLARDA KUZATING</h4>
                                <ul>
                                    <li>
                                        <a href="#">
                                            <i className="fa fa-facebook" aria-hidden="true" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fa fa-twitter" aria-hidden="true" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fa fa-google-plus" aria-hidden="true" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fa fa-youtube" aria-hidden="true" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fa fa-whatsapp" aria-hidden="true" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </section>
        </div>
    )
}

export default LayoutPage;
